import React, { useState, useEffect } from 'react';
import Obfuscate from 'react-obfuscate';
import Concept from './components/Concept';
import Page from './components/Page';
import './App.css';

function App() {

  const [currentSection, setSection] = useState("flow");
  const [scrollY, setScrollY] = useState(0);
  const sections = ["flow", "story", "gameplay", "strategy"];

  const onSectionClick = (e) => {
    const sectionName = e.target.id.split("-")[1];
    setSection(sectionName);
  }

  const onScroll = (e) => {
    // Assume we are only scrolling the Y
    setScrollY(window.scrollY);
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
  }, []);



  return (
    <div className="App" onScroll={onScroll}>
      <Page name="Hero" color="white" className="flexCenter" scrollY={scrollY}>
        <div className="center">
          <div id="heroSpacer" />
          <div id="heroTagline" className="subheader">Pinball for the Modern Gamer</div>
        </div>
      </Page>
      <Page name="Paradigm" color="gray" className="flexCenter" scrollY={scrollY}>
        <div className="header">
          We are changing the <strong>paradigm</strong> of pinball for the twenty-first century
        </div>
        <div>
          <p>Say goodbye to pinball only focused on high scores and consuming quarters.</p>
          <p>Say goodbye to starting at the beginning every game and never making it to the end.</p>
          <p>Say goodbye to flashy gimmicks and expensive toys that mask shallow, simple games.</p>
          <p />
        </div>
        <div className="subheader">
          Say hello to the new pinball.
        </div>
      </Page>
      <Page name="Modern" color="orange" className="flexTwoColumn" scrollY={scrollY}>
        <div className="flexColumn">
          <div className="header" style={{ paddingRight: "24pt" }}>
            <strong>Paradigm Tilt</strong><br />
            designs pinball games for the modern gamer
          </div>
        </div>
        <div className="flexColumn">
          <div className="subheader">
            <strong>Story-Focused Themes</strong>
          </div>
          <div>
            create immersive worlds, compelling objectives, and satisfying progressions
          </div>
          <div className="subheader">
            <strong>Sophisticated Rulesets</strong>
          </div>
          <div>
            challenge players of all skill levels and encourage experimentation and replay
          </div>
          <div className="subheader">
            <strong>Strategy-Driven Gameplay</strong>
          </div>
          <div>
            empowers the player to forge a unique path suited to their play style
          </div>
        </div>
      </Page>
      <Page name="ME2" color="white" scrollY={scrollY}>
        <div className="header center">Mass Effect 2: <span className="nowrap">the Pinball Game</span></div>
        <p className="right" style={{ maxWidth: "600px", marginLeft: "auto" }}>
          To demonstrate the tenets of Paradigm Tilt pinball design, we created a custom
          pin based on the video game <em className="nowrap">Mass Effect 2</em>.
        </p>
        <div className="flexTwoColumn">
          <div className="flexColumn center">
            <img className="mockupImage" src="./images/mockup-me2.png" alt="Mass Effect pinball machine" />
          </div>
          <div className="flexColumn right">
            <p><strong>Over a dozen story missions</strong> take the you across the galaxy in an epic adventure far more expansive any other pinball game.</p>
            <p><strong>Choose your squadmates</strong> by unlocking and completing their recruitment missions—in whatever order you want. Each choice you make impacts the story and your final mission!</p>
            <p><strong>Complete side quests and unlock upgrades</strong> to boost abilities&mdash;like combat multipliers, time extenders, and special powers—that you can carry across multiple games.</p>
            <p><strong>Save your profile</strong> and progress through the game across multiple play sessions. Complete the story on your schedule, then play again with different choices for a new experience!</p>
            <p><strong>Play with your friends</strong> in competitive and cooperative modes, from simple challenge modes to full speed-runs.</p>
          </div>
        </div>
      </Page>
      <Page name="Tenets" color="gray" className="flexCenter start" scrollY={scrollY}>
        <div className="header">Designing with Tenets</div>
        <p>
        The game design of <span className="nowrap">Paradigm Tilt</span> is driven by the principles of Pinball Theory, a comprehensive framework for understanding how pinball games interact with and engage the player at all levels.
        </p>
        <div className="flexGrid">
          { sections.map(section => (
            <div id={"section-" + section} key={section}
              className={"sectionLink" + (section === currentSection ? " selected" : "")}
              onClick={onSectionClick}
            >
              {section}
            </div>
          ))}
        </div>
        <div className="tenetContainer">
          {currentSection === "flow" && (
            <div className="tenet">
              Flow describes the physical arrangement of items on the playfield and how the ball moves between them. Categorizing a game’s flow provides metrics for comparing how the layouts of different games impact the perceptions of speed, fairness, and difficulty of a playfield.
              <br /><br />
              At Paradigm Tilt we design layouts that measure in the upper ranges of flow, with high velocity and momentum driving the action while high control and visibility empower the player.
            </div>
          )}
          {currentSection === "story" && (
            <div className="tenet">
              The story of a pinball game starts with the theme, which describes everything about the game not physically represented. The story progresses via modes and their objectives, and that progression describes the game’s superobjective.
              <br/><br/>
              At Paradigm Tilt we specialize in crafting non-linear narrative stories with compelling superobjectives, and building the narrative arc through each mode objective and player choice.
            </div>
          )}
          {currentSection === "gameplay" && (
            <div className="tenet">
              There are four types of relationships between the game’s story and the actual gameplay mechanics that the player interacts with—specifically, how each mode’s physical gameplay mechanic relates to the intangible objectives presented by the story and the theme.
              <br /><br />
              At Paradigm Tilt we focus on direct gameplay mechanics as much as possible&mdash;minimum 80% of all mode objectives&mdash;and use arbitrary mechanics only when it makes sense. We avoid ambient mechanics alltogether, to ensure an engrossing and tactile pinball experience.
            </div>
          )}
          {currentSection === "strategy" && (
            <div className="tenet">
              Strategy refers to opportunities the player has to make decisions that materially impact the gameplay and story. Most pinball games offer very few strategic opportunities, instead relying on randomness and progression ladders.
              <br/><br/>
              At Paradigm Tilt we believe that player choice is a major impact on investment, engagement, and replay value of a pinball game, and focus on creating game experiences that reward players with impactful choices.
            </div>
          )}
        </div>
      </Page>
      {/*
      <Page name="Concepts" color="orange" className="flexCenter" scrollY={scrollY}>
        <div className="header center">The First Project</div>
        <div className="subheader center">
          We are currently evaluating four unique concepts to choose the first retail <span className="nowrap">Paradigm Tilt</span> game.
        </div>
        <div className="flexGrid justify">
          <Concept name="Star">
            A high-stakes space adventure with the clock running out and the fate of humanity in your hands
          </Concept>
          <Concept name="Stone">
            A nostalgic  dungeon crawler full of monsters, quests, and 8-bit bravery
          </Concept>
          <Concept name="Steam">
            A retro take on the modern platformer filled with jaunty tunes, memorable characters and plenty of heart
          </Concept>
          <Concept name="Sand">
            An andrenaline rush of adventure in a strange new world full of danger, deception, and treasure
          </Concept>
        </div>
      </Page>
      */}
      <Page name="Contact" color="white" className="flexTwoColumn" noChevron scrollY={scrollY}>
        <div className="flexColumn">
          <div className="header center">Contact</div>
          <div className="contactContainer">
            <img src="images/contact-email.png" className="contactLogo" alt="email" />
            <Obfuscate className="contactText" email="queries@paradigm-tilt.com" />
          </div>
          <div className="contactContainer">
            <img src="images/contact-facebook.png" className="contactLogo" alt="facebook" />
            <Obfuscate className="contactText" href="https://www.facebook.com/ParadigmTilt" target="pt-external">facebook.com/ParadigmTilt</Obfuscate>
          </div>
          <div className="contactContainer">
            <img src="images/contact-twitter.png" className="contactLogo" alt="facebook" />
            <Obfuscate className="contactText" href="https://www.twitter.com/ParadigmTilt" target="pt-external">twitter.com/ParadigmTilt</Obfuscate>
          </div>
        </div>
      </Page>
      <div className="footer">
        &copy; Copyright 2020 Paradigm Tilt LLC, Seattle WA
      </div>
    </div>
  );
}

export default App;
