import React, { useEffect, useState } from 'react';
import './Page.css';

const Y_SHIFT = 200

function Page(props) {
    const [bounds, setBounds] = useState([])
    const [ele, setEle] = useState();
    
    useEffect(() => {
        let startY, endY, screenHeight;
        const element = document.getElementById("page" + props.name);
        setEle(element);
        screenHeight = window.innerHeight;
        startY = element.offsetTop;
        endY = element.offsetTop + element.offsetHeight;
        // We have to have bounds by the Y_SHIFT so we trigger in time
        setBounds([startY - screenHeight - Y_SHIFT, endY + screenHeight]);
    }, [props.name])
    
    useEffect(() => {
        const { scrollY } = props;
        if (!ele || scrollY < bounds[0] || scrollY > bounds[1]) {
            return;
        }
        const topOffset = ele.offsetTop - scrollY;
        const screenHeight = window.innerHeight;
        const style = ele.children[0].style;

        // If topOffset is positive, we haven't hit the top yet
        if (topOffset > 0) {
            let pctOffset = topOffset / screenHeight;
            style.opacity = (120 - pctOffset * 120) + "%";
            style.transform = "translateY(" + Y_SHIFT * pctOffset + "px)";
        } else {
            style.opacity = "100%";
            style.transform = "translateY(0px)";
        }
    }, [props.scrollY])

    return (
        <div id={"page" + props.name} className={"page " + props.color + (props.noChevron ? " noChevron" : "")}>
            <div id={"pageInner" + props.name} className={"pageInner " + props.className}>
                {props.children}
            </div>
            <div className="borderBox" />
        </div>
    )
}

export default Page;